import React, { useEffect } from "react";
import "../css/About.css";
import thisimg from "../assets/ace6.png";
import { AiOutlineAreaChart } from "react-icons/ai";
import { GiMiner } from "react-icons/gi";
import { IoMdSchool } from "react-icons/io";
import { FaPeopleArrows } from "react-icons/fa";
import { Link } from "react-router-dom";

const about = [
  {
    icon: <GiMiner size={40} color="#fff" />,
    title: "CRYPTO-MINING",
    text:
      "Cryptocurrency mining is an extremely fast transactional validation process within a blockchain where community computing power is used to secure the network through specialized electronic equipment.",
  },
  {
    icon: <FaPeopleArrows size={40} color="#fff" />,
    title: "ARBITRAGE",
    text:
      "Arbitrage is a financial strategy that consists in taking advantage of the price difference between different markets on the same financial asset to obtain an economic benefit, usually without risk.",
  },
  {
    icon: <AiOutlineAreaChart size={40} color="#fff" />,
    title: "TRADING",
    text:
      "Trading consists of the purchase and sale of assets such as stocks, futures, currencies or derivatives in a financial market.",
  },
  {
    icon: <IoMdSchool size={40} color="#fff" />,
    title: "Academy",
    text:
      "We provide trading and financial courses for all our users depending on your investment on our platform. We hope to train you to your own financial freedom.",
  },
];

const About = () => {
  const fadeIn = () => {
    let fadeEl = document.querySelectorAll(".no-fade");
    for (let el of fadeEl) {
      let rect = el.getBoundingClientRect();

      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight + rect.height ||
            document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      ) {
        el.classList.remove("no-fade");
        el.classList.add("div-fade");
      }
    }
  };

  useEffect(() => {
    const window_scroll = window.addEventListener("scroll", () => {
      fadeIn();
    });

    return window.removeEventListener("scroll", window_scroll);
  }, []);
  return (
    <div>
      <div className="about-container">
        {/* <img src={btcimg} className="image" /> */}
        <div className="about-about">
          <div className="about-about-inside no-fade">
            <p>WHO WE ARE</p>
            <h3>ABOUT ACECOINS</h3>
            <p className="about-text">
              Acecoins is one of the leading Defi in the cryptocurrency trading
              industry. Our community offers a highly profitable investment for
              everyone. Years of trading experience in the multi-currency
              markets provide a stable income not only for company but also for
              numerous community members around the world. In conjuction with
              our best in class investment portfolio, we have built an Academy
              where members are offered the oppurtunity to gain financial
              literacy. We believe that financial literacy is the key difference
              maker and provides a strong foundation for individuals to take
              control of their personal economy and work towards a better
              future.
            </p>
          </div>
          <div className="about-info">
            {about.map((a) => (
              <div className="no-fade">
                {a.icon}
                <h5 style={{ marginTop: 20 }}>{a.title}</h5>
                <p>{a.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="philosophy-container">
        <div className="philosophy">
          {/* <p>
            Our research and experience show that by also considering the
            quality of a company, as well as its earnings growth and market
            sentiment, our ability to achieve more stable returns through
            shorter-term market cycles is enhanced. We call this “driving with
            the headlights on”. Built into this research is a deep understanding
            of the various portfolio and share risks. We believe that skilful
            risk management, not avoidance, can enhance returns within our
            client portfolios. Our disciplined portfolio construction process
            manages behavioural bias, drives debate within the team and enables
            us to invest with conviction. Our clients are at the heart of
            everything we do. Our team’s incentives are aligned to the
            performance of our funds and the individual shares we select. This
            ensures that our clients’ interests drive our investment decisions.
          </p> */}
          <div className="whatwedocontainer">
            <article>
              <h5>
                <b>Who we are</b>
              </h5>
              <p>
                We are a decentralised financial community with great
                specialists in diffent technological, financial and commercial
                fields, supporting all our operations in 4 main pillars that
                are: Arbitrage, Mining, Trading and Financial Education.
              </p>
            </article>
            <article>
              <h5>
                <b>What we do</b>
              </h5>
              <p>
                We believe that money without financial education is money soon
                gone. We are here to provide money and financial education for
                all our community members while offering them the opportunity to
                earn by taking advantage of the opportunities offered by
                financial systems. Using the next generation technologies that
                facilitate commercial exchange and allow us to obtain excellent
                results for our community members.
              </p>
            </article>
            <article>
              <h5>
                <b>Our mission</b>
              </h5>
              <p>
                Our mission is to provide financial decentralision worldwide,
                and to make the humblest communities an entity of thriving
                prosperity, while offering financial education to the undeserved
                by financial institutions globally. We believe that financial
                literacy is the key difference maker, and provides a strong
                foundation for individuals to take control of their personal
                economy and work towards a better future.
              </p>
            </article>
          </div>
        </div>
        <div className="start">
          <h5>START INVEST NOW</h5>
          <p>
            We provide 24/7 trading access, executed by our team of experienced
            traders with trading backgrounds in the traditional banking
            industry.
          </p>
          <Link to="/register">Register Now</Link>
        </div>
        <img src={thisimg} />
      </div>
    </div>
  );
};

export default About;
