import React from "react";
import "../css/Signupfooter.css";
import { Link } from "react-router-dom";

function Signupfooter() {
  return (
    <div className="footer  ">
      <div className="footer2">
        <p>© 2020 Acecoins. All Rights Reserved.</p>

        <ul>
          <li>
            {" "}
            <Link to="/terms">Terms & Condition</Link>
          </li>
          <li>
            {" "}
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            {" "}
            <Link to="/contact">Contact us</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Signupfooter;
