import React, { useState, useEffect, useContext } from "react";
import { Form, InputGroup, Container, Alert, Button } from "react-bootstrap";
import "../css/Signup.css";
import Signupfooter from "./Signupfooter";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { loginEndpoint } from "../endpoints/api";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { UserContext } from "../context/userDetails";

function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [eyeOnOff, setEyeOnOff] = useState(false);
  const [userDetails, setUserDetails] = useContext(UserContext);
  const [variant, setVariant] = useState("danger");

  let navigate = useNavigate();
  let location = useLocation();

  const submit = async (e) => {
    e.preventDefault();
    let res = await loginEndpoint(
      email.toLowerCase().replaceAll(" ", ""),
      password
    );
    setLoading(false);
    //console.log(res);
    try {
      if (res.response.data === undefined) {
        setErrorMessage(
          "An error occurred while submitting your request, plaese try again!"
        );
        setVariant( "danger" );
        window.scrollTo(0, 0);
        return setShow(true);
      }
      if (res.response.data.error) {
        setErrorMessage(res.response.data.message);
        setVariant("danger");
        window.scrollTo(0, 0);
        return setShow(true);
      }
    } catch (error) {}

    setUserDetails(res.data);
    navigate("/dashboard");
    // console.log(res);
    // let res2 = await dashboardEndpoint();
    // console.log(res2);
  };

  useEffect(() => {
    // console.log(location)
    document.title = "Login | Acecoins";
    if (location.state !== null) {
      if (location.state.error) setVariant("danger");
      if (!location.state.error) setVariant("success");
      setErrorMessage(location.state.message);
      return setShow(true);
    }
  }, []);
  return (
    <div className="divform">
      <Alert
        show={show}
        variant={variant}
        onClose={() => setShow(false)}
        dismissible
        style={{ fontFamily: "Nunito" }}
      >
        {errorMessage}
      </Alert>
      <div className="form">
        <div className="logoContainer">
          <img
            className="row justify-content-center"
            src={require("../assets/acecoinlogo-2x.png")}
            alt="logo"
            style={{
              height: "62px",
              width: "60px",
              textAlign: "center",
              margin: "auto",
            }}
          />
        </div>

        <div className="textfield">
          <Container>
            <h4 className="nk-block-title">Sign-In</h4>
            <Form>
              <Form.Group className="mb-3 mt-4" controlId="formBasicPassword">
                <Form.Label className="label">Email</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter your email address"
                  className="inputField"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 mt-4" controlId="formBasicPassword">
                <Form.Label className="label" style={{ width: "100%" }}>
                  Password{" "}
                  <Link to="/forget">
                    <span style={{ float: "right" }} className="text-primary">
                      Forgot Password?
                    </span>
                  </Link>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={eyeOnOff ? "text" : "password"}
                    placeholder="Enter your passcode"
                    className="inputField"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <InputGroup.Text
                    style={{ cursor: "pointer" }}
                    onClick={() => setEyeOnOff(!eyeOnOff)}
                  >
                    {eyeOnOff ? <FiEyeOff /> : <FiEye />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <div className="d-grid">
                <Button
                  className="btn btn-primary pt-2 pb-2 mt-1 mb-3  cursor-pointer"
                  type="submit"
                  disabled={isLoading}
                  onClick={(e) => {
                    setLoading(true);
                    submit(e);
                  }}
                >
                  {isLoading ? "Loading…" : "Sign In"}
                </Button>
              </div>
              <div className="accountsignin">
                <p>
                  New on our platform?{" "}
                  <span>
                    {" "}
                    <Link to="/register"> Create an account</Link>
                  </span>
                </p>
              </div>
            </Form>
          </Container>
        </div>
      </div>
      <Signupfooter />
    </div>
  );
}

export default Signin;
