import React, { useEffect } from "react";
import "../css/Header.css";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { FaUserCheck } from "react-icons/fa";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";

import Carousel from "react-bootstrap/Carousel";

const how = [
  {
    icon: <FaUserCheck size={80} color="#6576ff" />,
    title: "Sign Up",
  },
  {
    icon: <GiReceiveMoney size={80} color="#6576ff" />,
    title: "Invest",
  },
  {
    icon: <GiTakeMyMoney size={80} color="#6576ff" />,
    title: "Earn Profit",
  },
];

const Header = () => {
  const fadeIn = () => {
    let fadeEl = document.querySelectorAll(".no-fade");
    for (let el of fadeEl) {
      let rect = el.getBoundingClientRect();

      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight + rect.height ||
            document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      ) {
        el.classList.remove("no-fade");
        el.classList.add("div-fade");
      }
    }
  };

  useEffect(() => {
    const window_scroll = window.addEventListener("scroll", () => {
      fadeIn();
    });

    return window.removeEventListener("scroll", window_scroll);
  }, []);
  return (
    <div className="header">
      <Carousel fade interval={2000}>
        <Carousel.Item>
          <div id="home" className="headerSlides headerSlides1">
            <h2 className="typed-text">Welcome to Acecoins</h2>
            <p className="banner-p">
              Acecoins is one of the leading Defi in the cryptocurrency trading
              industry. Our community offers a highly profitable investment for
              everyone.
            </p>
            <div className="h-links">
              {/* <a href=""></a> */}
              <Link to="/register">Get started</Link>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div id="home" className="headerSlides headerSlides2">
            <h2 className="typed-text">Gain High Profits with Low Risks</h2>
            <p className="banner-p">
              Arbitrage is a financial strategy that consists in taking
              advantage of the price difference between different markets on the
              same financial asset to obtain an economic benefit, usually
              without risk.
            </p>
            <div className="h-links">
              {/* <a href="">START NOW</a> */}
              <Link to="/dashboard/invest">Invest</Link>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div id="home" className="headerSlides headerSlides3">
            <h2 className="typed-text">Our Academy</h2>
            <p className="banner-p">
              We have built an Academy where members are offered the oppurtunity
              to gain financial literacy. We believe that financial literacy is
              the key difference maker and provides a strong foundation for
              individuals to take control of their personal economy and work
              towards a better future.
            </p>
            <div className="h-links">
              {/* <a href="">START NOW</a> */}
              <Link to="/academy">Academy</Link>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <div className="how-container">
        <h5>HOW IT WORKS</h5>
        <h3>THREE EASY WAY TO START</h3>
        <div className="how-info">
          {how.map((h) => (
            <div className="no-fade">
              {h.icon}
              <h5 style={{ marginTop: 20 }}>{h.title}</h5>
              <p>{h.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
