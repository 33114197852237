import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardInvest from "./components/DashboardInvest";
import DashboardPlan from "./components/DashboardPlan";
import DashboardProfile from "./components/DashboardProfile";
import Signup from "./components/Signup";
import Signin from "./components/Signin";
import HomePage from "./components/HomePage";
import UserProvider from "./context/userDetails";
import Start from "./components/Start";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Withdrawal from "./components/Withdrawal";
import VerifyMail from "./components/VerifyMail";
import Deposit from "./components/Deposit";
import Investment from "./components/Investment";
import PaymentInstructions from "./components/PaymentInstructions";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import Terms from "./components/Terms";
import AdminHome from "./admin/home";
import AdminUsers from "./admin/users";
import AdminWithdrawals from "./admin/withdrawals";
import Fund from "./admin/fund";
import UserInvestment from "./admin/investment";
import Academy from "./components/academy";
import AcademyContent from "./components/academyContent";
import History from "./components/History";

function App() {
  return (
    <div style={{
      width: '100%', height: '100vh', textAlign: 'center',
      fontSize: 18,
      color: '#444'
    }}>
      <h1 style={{ textAlign: 'center', fontSize: '50px', color: '#888', margin: '30px 0', }}>
        Site Under Maintenance
      </h1>
      <p>We apologize for the inconvenience. Our site is currently undergoing maintenance and will be back online soon. Please check back later.</p>
    </div >
    // <UserProvider>
    //   <Router className="App">
    //     <div id="google_translate_element"></div>
    //     <Routes>
    //       <Route path="/" element={<HomePage />} />
    //       <Route path="/register" element={<Signup />} />
    //       <Route path="/login" element={<Signin />} />
    //       <Route path="/dashboard" element={<DashboardProfile />} />
    //       <Route path="/dashboard/plan" element={<DashboardPlan />} />
    //       <Route path="/dashboard/invest" element={<DashboardInvest />} />
    //       <Route path="/investment/:id" element={<Start />} />
    //       <Route path="/forget" element={<ForgotPassword />} />
    //       <Route path="/reset/:token" element={<ResetPassword />} />
    //       <Route path="/confirm/:token" element={<VerifyMail />} />
    //       <Route path="/withdraw" element={<Withdrawal />} />
    //       <Route path="/deposit" element={<Deposit />} />
    //       <Route path="/deposit/pay" element={<PaymentInstructions />} />
    //       <Route path="/contact" element={<Contact />} />
    //       <Route path="/faq" element={<Faq />} />
    //       <Route path="/terms" element={<Terms />} />
    //       <Route path="/investment" element={<Investment />} />
    //       <Route path="admin/invest/:id" element={<Investment />} />
    //       <Route path="/admin" element={<AdminHome />} />
    //       <Route path="/admin/users" element={<AdminUsers />} />
    //       <Route path="/admin/withdrawals" element={<AdminWithdrawals />} />
    //       <Route path="/admin/fund/:id" element={<Fund />} />
    //       <Route path="/admin/investment/:id" element={<UserInvestment />} />
    //       <Route path="/academy" element={<Academy />} />
    //       <Route path="/academy/:subtitle" element={<AcademyContent />} />
    //       <Route path="/history" element={<History />} />
    //     </Routes>
    //   </Router>
    // </UserProvider>
  );
}

export default App;
